.root {
  background-color: #ddd;
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.list-group {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: unset;
}

.btn:focus {
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle,
.btn-light:hover,
.btn-light {
  border-color: transparent !important;
}

.dropdown-toggle {
  color: #0d1a40 !important;
  background-color: white !important;
  border-color: transparent;
  width: 100% !important;
  border-color: none !important;
  box-shadow: none !important;
  white-space: unset !important;
}

.dropdown-toggle1:hover {
  background-color: red I !important;
}

.dropdown-toggle1:focus {
  background-color: red I !important;
}

.dropdown {
  position: unset !important;
  border-color: transparent !important;
}

.dropdown-menu {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
  border: none !important;
  width: "-webkit-fill-available";
  border-radius: 0 !important;
  z-index: 99999;
  width: 100%;
}

.trialDropdownMobile {
  width: 100%;
}

.logout-Mobile {
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #0d1a40;
  padding-left: 0;
}

.toogle-desktop {
  margin-left: "-20px";
}

.logout {
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #000000;
  padding-left: 0;
}

.list-group-item {
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: #ffffff;
  border-bottom: 1px solid #ededed;
}

.desktop-top-feedback {
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #ffffff;
  font-weight: 700;
}

.desktopPro-vector {
  display: flex;
  gap: 9px;
  margin-left: -6px;
  color: #000000;
}

.dropdown-toggle::after {
  visibility: hidden;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  background-color: red I !important;
}

.down-icon {
  margin-left: 12px;
  width: 10px;
  height: 16px;
}

/* input[type='radio'] { */
/* -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
  border: 1px solid gray;
  accent-color: #0D1A40;
}

input[type="radio"]:checked:before {
  background: #E08330;  
}
input[type="radio"]:checked {
border-color: #E08330;
} */

.dropdown-item.active,
.dropdown-item:active {
  color: #212529;
  background-color: transparent;
}

.center-spinner-full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh !important;
}

.custom-spinner {
  width: 134px;
  height: 134px;
  color: green;
}

.date-spinner {
  color: #0d1a40 !important;
  width: 134px;
  height: 134px;
}

a:hover {
  color: transparent;
  text-decoration: none;
  color: #808080;
}

.input-label {
  font-size: 12px;
  font-weight: 400;
  color: #0d1a40;
}

.right-bordered-div {
  border-right: 1px solid #b8b8b8;
  padding-right: 10px;
}

input[type="checkbox"] {
  accent-color: #0d1a40;
}

.assessment-label {
  font-size: 10px;
  font-weight: 600;
  color: #0d1a40;
}

.input-label-checkbox {
  line-height: 20px;
  color: #0d1a40;
}

.navbar {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.leaflet-control-rotate-toggle {
  background-color: white !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.rating-close-button {
  cursor: pointer;
  color: #0d1a40;
}

.close-button {
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
  color: #0d1a40;
  font-size: 20px;
}

.trialDropdownTablet {
  width: "-webkit-fill-available";
}

.mobileTrial {
  background-color: #ffe8bc !important;
  width: 100% !important;
}

.removeBackTrial {
  background-color: white !important;
}

#tooltip-top > .tooltip-inner {
  background-color: #0d1a40;
}

@media (min-width: 576px) and (max-width: 991px) {
  .trial-dropdown .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }
}

.trial-bar.row:after {
  padding: 0px;
}

.new-viewer-page .row:after {
  display: none;
}

.trial-bar {
  height: 100%;
  width: 100%;
  padding: 0;
  flex: 1;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  display: flex;
  background-color: #fff;
  align-items: center;
  z-index: 9999;
  position: relative;
}

.assessment-dropdown.dropend button {
  width: 100%;
  align-self: flex-start;
  text-align: left;
  height: 49px;
  padding: 0 12px;
  border-bottom: 1px solid rgb(237, 237, 237);
  border-radius: 0;
}

.assessment-dropdown.assessment-dropdown-type.dropend button {
  height: 32px;
  padding: 0 16px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.assessment-main-dropdown .dropdown-menu {
  transform: translate3d(0px, 60px, 0px) !important;
}

.assessment-dropdown.dropend .dropdown-menu {
  width: 290px;

  min-height: 304px !important;
  max-height: 350px;
  left: 360px !important;
  top: -60px !important;
  position: absolute !important;
  box-shadow: none !important;
  border: 1px solid #f9f9f9 !important;
}

.assessment-dropdown.assessment-dropdown-tablet.dropend .dropdown-menu {
  left: 330px !important;
}

@media (min-width: 576px) and (max-width: 869px) {
  .assessment-dropdown.dropend .dropdown-menu {
    width: 300px;
    transform: translate3d(0px, 60px, 0px) !important;
    left: 330px !important;
  }

  .assessment-main-dropdown.assessment-main-dropdown-tablet .tablet-custom-menu.dropdown-menu {
    transform: translate3d(-50px, 60px, 0px) !important;
  }
}

.trial-dropdown .dropdown-menu {
  min-width: fit-content;
  transform: translate3d(0px, 60px, 0px) !important;
}

span.assessment-option {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.assessment-row-hover:hover,
.assessment-row-hover:focus {
  background-color: #ffe8bc;
}

.assessment-dropdown.show.dropend .dropdown-toggle {
  background-color: #ffe8bc !important;
}

.assessment-group {
  padding-top: 12px !important;
}

button.assessment-mobile-button {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: #0d1a40 !important;
  position: relative;
}

.assessment-mobile-container-portrait {
  position: absolute;
  top: 0px;
  height: inherit;
  width: 100%;
  max-width: 100%;
  padding: 0px;
  z-index: 9999;
  background-color: white;
  overflow: auto;
}

.assessment-mobile-container-landscape {
  position: absolute;
  top: 0px;
  height: calc(100vh - 60px);
  width: 100%;
  max-width: 100%;
  padding: 0px;
  z-index: 9999;
  background-color: white;
  overflow: auto;
}

.flight-date-wrapper {
  width: 200px;
  position: fixed !important;
  top: 182px;
  z-index: 99;
}

.flight-date-button {
  background-color: white;
  height: "48px";
  width: "40px";
}

.flight-date-button:hover,
.flight-date-button:active,
.flight-date-button:focus {
  background-color: white !important;
  color: black !important;
  border: none;
}

.flight-date-container {
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  width: 280px;
  max-height: 300px;
  overflow: auto;
  top: 0;
  margin-left: 42px;
  padding: 12px 24px;
}

.flight-date-title {
  color: #0d1a40;
  font-size: 12px;
  font-style: normal;
  padding-top: 12px;
}

.landScapeDropdown {
  height: 200px;
  max-height: 250px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #293306;
  border-radius: 5px;
}

.assessment-tab {
  background-color: transparent !important;
  margin-top: 0px;
}

.movable-list {
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  width: 100%;
}

.orderarray {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 700;
}

.type-date {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
}

.mobile-type-date {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 700;
  width: 64%;
}

.zoom-icon-at-fifty .leaflet-control-zoom.leaflet-bar.leaflet-control {
  bottom: 45vh;
}

.zoom-icon-at-fifty .zoomviewcontainer {
  bottom: 42vh !important;
}

.zoom-move-to-left .leaflet-control-zoom.leaflet-bar.leaflet-control {
  right: 52.5% !important;
}

.zoom-move-to-left .zoomviewcontainer {
  right: 51% !important;
}

.mobileLandscapeScroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 86%;
}

.mobile-icon {
  height: 40px;
  width: 40px;
  border: none;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 1px;
}

.mobile-icon:hover,
.mobile-icon:focus,
.mobile-icon:active {
  background-color: white !important;
  border-color: white !important;
  box-shadow: none !important;
}

.mobile-icon:disabled {
  cursor: not-allowed;
  opacity: 1;
  background-color: #f3f3f3 !important;
}

.tools-button {
  height: 48px;
  width: 40px;
  border: none;
  background-color: white !important;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 2px;
  margin: 1px;
  position: relative;
  z-index: 99;
}

.tools-button:disabled {
  cursor: not-allowed;
  opacity: 1;
  background-color: #f3f3f3 !important;
}

.isGrey,
.isGrey:hover,
.isGrey:focus,
.isGrey:active {
  background-color: #ffe8bc !important;
}

.isWhite,
.isWhite:hover,
.isWhite:focus,
.isWhite:active {
  background-color: white !important;
}

.feedback .modal-dialog .modal-content {
  height: 100vh !important;
  display: table;
}

.feedback .modal-dialog,
.thankyou-feedback .modal-dialog {
  margin: 0 !important;
}

.tabletFeedback .modal-dialog {
  max-width: 100vw !important;
}

.tabletFeedback {
  z-index: 9999 !important;
}

.new-viewer-page .leaflet-control-rotate.leaflet-bar.leaflet-control {
  width: 40px;
  height: 48px;
  left: 1px;
  bottom: calc(15% + 117px);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 2px;
}

.new-viewer-page .map-on-mobile .leaflet-control-rotate.leaflet-bar.leaflet-control {
  height: 40px;
  left: 0px;
  box-shadow: none;
}

.new-viewer-page a.leaflet-control-rotate-toggle {
  height: 48px !important;
  width: 40px !important;
  border-radius: 6px !important;
}

.new-viewer-page .map-on-mobile a.leaflet-control-rotate-toggle {
  height: 40px !important;
  border-radius: 6px !important;
}

.desktopFeedbackModal .modal-dialog {
  max-width: 640px !important;
}

.desktopFeedbackModal .modal-dialog .modal-content {
  padding: 14px !important;
}

.fade.desktopFeedbackModal.modal.show {
  z-index: 9999 !important;
}

.feedbackTitle {
  font-size: 24px;
  font-weight: 700px !important;
  font-family: Helvetica, sans-serif;
}

.thank-modal-button {
  border: none;
  background-color: rgb(41, 51, 6);
  border-radius: 20px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  position: relative;
  right: 41%;
  width: 90px;
  height: 42px;
  margin: 16px 0px;
}

.thank-you-message {
  padding-left: 14px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.thank-you-modal-text {
  padding-left: 30px;
  font-size: 24px;
  font-weight: bold;
}

.new-viewer-page .leaflet-control-measure-toggle.js-toggle {
  display: none !important;
}

.new-viewer-page a.js-start-area.start,
.new-viewer-page a.js-start-linear.start {
  color: #6269cd !important;
}

.desktopTrialSummary .modal-content {
  position: absolute;
  top: 7%;
  width: 80vw;
  height: 570px;
  font-size: 0.75rem;
  z-index: 0;
  margin: auto;
}

.desktopTrialSummary .modal-dialog {
  justify-content: center;
  min-height: 100px !important;
}

.mobileTrialSummary .modal-dialog {
  height: 100vh;
  margin: 0px;
}

.mobileTrialSummary {
  padding-left: 0;
}

.tabTrialSummary .modal-dialog {
  justify-content: center;
  min-height: 100px !important;
  top: 19%;
  max-width: 100vw !important;
}

.landScapeTrialSummary .modal-dialog {
  display: contents;
}

.mobileTrialSummary .modal-content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  font-size: 0.75rem;
  z-index: 0;
  margin: auto;
  overflow-y: scroll;
}

.tabTrialSummary .modal-content {
  width: 100vw;
  height: 680px;
  font-size: 0.75rem;
  z-index: 0;
  margin: auto;
}

.desktopBarModel .modal-content {
  position: absolute;
  width: 80vw;
  height: 100vh;
  font-size: 0.75rem;
  z-index: 0;
  margin: auto;
}

.desktopBarModel .modal-dialog {
  right: 20%;
}

.map-loader {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100% !important;
  opacity: 1;
  position: absolute;
  z-index: 9999;
}

.new-layout-loader {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100% !important;
  opacity: 1;
  position: absolute;
  z-index: 9999;
  padding-bottom: 5%;
}

.parent {
  position: relative;
  z-index: 99;
}

.checkbox-label {
  cursor: pointer;
}

.mobile-hamburger-icon {
  color: white;
  border-radius: 50%;
  border: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0d1a4080;
}

.mobile-hamburger-icon-color {
  background: none !important;
  color: #0d1a40 !important;
  border-radius: 50%;
  border: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktopRatingModal .modal-open .modal {
  overflow-y: hidden;
}

.desktopRatingModal .modal-dialog {
  max-width: 838px !important;
}

.mobileDesktopRatingModal .modal-dialog {
  height: 100vh !important;
  width: 100vw !important;
  margin: 0 !important;
}

.mobileDesktopRatingModal .modal .show {
  padding-left: 0 !important;
}

.mobile-landscape-RatingModal .modal-dialog {
  height: 100vh !important;
  width: 100vw !important;
  margin: 0 !important;
  max-width: 100vw !important;
}

.fade.mobile-landscape-RatingModal.modal.show {
  overflow-y: hidden !important;
}

.modal-open .modal {
  overflow-y: scroll;
}

.tabletRating .modal-dialog {
  max-width: 100vw !important;
  height: 942px;
  top: 9%;
}

.tabletRatingModalBody {
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 500px;
}

.RatingModalHeader {
  font-weight: bold;
  font-size: 24px;
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
  cursor: move;
}

.desktopRatingModalBody {
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 400px;
  color: #0d1a40;
}

.desktopRatingModalBodySearch {
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 400px;
  height: 400px;
}

.tabletRatingModalBodySearch {
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 500px;
  height: 500px;
}

.mobileRatingModalBodySearch {
  overflow-y: scroll !important;
  overflow-x: hidden;
  height: 800px;
}

.mobileRatingModalBody {
  overflow-y: scroll !important;
  overflow-x: hidden;
  height: calc(100vh - 190px);
}

.desktopRatingModalKeyContent {
  font-weight: bold;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  padding-bottom: 4px;
  margin: 20px 0 0 8px;
  padding: 0 10px;
  color: "#0D1A40";
}

.desktopRatingModalValueContent {
  font-weight: 400;
  font-size: 14px;
  font-family: Helvetica, sans-serif;
  line-height: 18.4px;
  margin: 8px 8px 20px 8px;
  padding: 0 10px;
  color: "#0D1A40";
}

.desktopSearchIcon {
  position: absolute;
  right: 27%;
  top: 7%;
}

.tabletSearchIcon {
  position: absolute;
  right: 33%;
  top: 5%;
}

.mobileSearchIcon {
  position: absolute;
  right: 17%;
  top: 6%;
}

.assessment-search-icon {
  color: black;
  cursor: pointer;
  position: relative;
  top: 14px;
  right: 28px;
  z-index: 99;
}
.trial-report-assessment-search-icon {
  color: black;
  cursor: pointer;
  position: relative;
  top: 21px;
  right: 28px;
  z-index: 99;
}

.assessment-search-icon1 {
  color: black;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 18px;
  z-index: 99;
}

.menu-bottom-border {
  border-bottom: 1px solid rgb(237, 237, 237);
}

.clear-all-button {
  color: #0d1a40;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 1px solid #0d1a40;
  border-bottom: none !important;
  width: 100%;
  height: 48px !important;
  text-align: right !important;
  display: flex;
  justify-content: flex-end;
}

.icon-up-down {
  float: right;
}

.fade.mobileDesktopRatingModal.modal.show {
  padding-left: 0 !important;
  overflow-y: hidden !important;
}

.card-body {
  font-size: 12px;
  padding: 0;
}

.RatingModalSearch {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  width: 439px;
  border-radius: 20px !important;
  display: flex;
  margin: 8px auto 35px auto;
  background-color: #f1f2f3 !important;
  outline: 1px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.MobileRatingModalSearch {
  width: 300px;
  display: flex;
  margin: 8px auto 35px auto;
  border-radius: 20px !important;
  background-color: #f1f2f3 !important;
  outline: 1px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.RatingModalSearchInputGroup {
  width: 439px !important;
}

.MobileRatingModalSearchInputGroup {
  width: 300px !important;
}

.AssessmentSearchInputGroup {
  min-width: 200px !important;
  margin: 0px 10px;
  position: relative;
}

.accordion-header {
  margin-bottom: 0px;
  font-size: 12px;
}

.accordion-button {
  width: 100%;
  background-color: white;
  border: none;
  border-bottom: none;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-right: 0.5px solid rgba(0, 0, 0, 0.25);
  border-left: 0.5px solid rgba(0, 0, 0, 0.25);
}

.AccodionHeading {
  float: left;
  padding: 9px;
  font-weight: 700;
  color: #0d1a40;
}

.accordion-body .container {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}

.accordion-button .collapse-icon {
  width: 20px;
  height: 18px;
  fill: #0d1a40;
  margin-top: 8px;
}

.heat-map-legends-container {
  position: absolute;
  z-index: 9;
  width: 92px;
  top: 84px;
  right: 24px;
  background-color: white;
  border-radius: 5px;
}

span.legend-label {
  font-size: 12px;
  padding-left: 8px;
  font-weight: 400;
  color: #000;
}

.legend-box {
  width: 12px;
  height: 128px;
}

.parent-div-accordion:first-child {
  margin-top: 10px;
}

.desktopRatingModal .modal-content {
  height: 942px;
  max-height: 569px;
  border: none !important;
}

.tabletRating .modal-content {
  height: 942px;
  max-height: 700px;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
  height: 128px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  color: #0d1a40;
}

.heat-map-legends-container.mobile-legends {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  height: 32px;
}

.mobile-legends .legend-box {
  height: 12px;
  width: 200px;
}

.mobile-legends ul.no-bullets {
  flex-direction: row-reverse;
}

.mobile-icon.disabled,
.mobile-icon:disabled {
  border-color: white !important;
}

.mobile-hamburger-icon:hover,
.mobile-hamburger-icon:active,
.mobile-hamburger-icon:focus,
.mobile-hamburger-icon:active:focus {
  background-color: #0d1a4080 !important;
  border-color: #0d1a4080 !important;
  box-shadow: none !important;
}

.mobile-trial-summary .js-plotly-plot .plotly .modebar {
  left: 28% !important;
}

.trial-summary-popup.center-spinner {
  height: 80vh !important;
}

.mobile-landscape-flightdate-title {
  color: #0d1a40;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  padding: 12px 0 12px 0;
  margin-left: 85px;
}

.landscape-flightDate {
  color: #0d1a40;
  font-size: 14px;
  font-weight: 400;
  margin-left: 85px;
  padding-top: 12px;
  display: block !important;
}

.flightDate {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #0d1a40;
  margin-left: 85px;
}

.fade.desktopRatingModal.modal.show {
  overflow-y: hidden;
}

.fade.tabletRating.modal.show {
  overflow-y: hidden;
}

.send-feedback-popup.trial-summary-popup.center-spinner {
  position: absolute;
  width: 100%;
  height: 100% !important;
  z-index: 1;
}

.content-loading .modal-body {
  opacity: 0.3;
}

.ass-clear-all-position {
  float: right;
  margin-right: 12px;
  border-bottom: 1px solid;
  cursor: pointer;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  color: #0d1a40;
}

.trial-ass-clear-all-position {
  float: unset;
  margin-right: 12px;
  border-bottom: 1px solid;
  cursor: pointer;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  color: #0d1a40;
  font-size: 14px;
}

.flightName-landscape {
  margin-left: 40% !important;
}

.traildropdown-mobile-container {
  position: absolute;
  top: 60px;
  height: 200px;
  width: 100%;
  max-width: 100%;
  padding: 0px;
  z-index: 9999;
  background-color: white;
  overflow: auto;
}

button.traildropdown-mobile-button {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: #0d1a40 !important;
}

.mobile-traildropdown-listitem {
  padding: 15px 0 0 66px;
}

.mobile-landscape-traildropdown {
  padding: 15px 0 0 9%;
}

.small-landscape-traildropdown {
  padding: 15px 0 0 110px;
}

.traildropdown-options {
  font-size: 12px;
  color: #0d1a40;
  cursor: pointer;
  padding-left: 5px;
}

.mobile-hamburger-icon.mobile-hamburger-icon-position {
  position: fixed;
  z-index: 999;
  left: 15px;
}

.mobile-hamburger-icon.three-dots-icon-position {
  position: fixed;
  z-index: 999;
  right: 15px;
}

.mobile-hamburger-icon.mobile-hamburger-LandScapeicon-position {
  position: fixed;
  z-index: 999;
  left: 8px;
}

.mobile-hamburger-icon.mobile-hamburger-SmallLandScapeicon-position {
  position: fixed;
  z-index: 999;
  left: 25px;
}

.mobile-hamburger-icon.mobile-hamburger-InsideLandScapeicon-position {
  position: absolute;
  z-index: 999;
  left: 22px;
  background: none !important;
  color: #0d1a40 !important;
}

.mobile-hamburger-icon.mobile-hamburger-InsideSmallLandScapeicon-position {
  position: absolute;
  z-index: 999;
  left: 34px;
  background: none !important;
  color: #0d1a40 !important;
}

.mobile-hamburger-icon.mobile-hamburger-Insideicon-position {
  position: absolute;
  z-index: 999;
  left: 15px;
  background: none !important;
  color: #0d1a40 !important;
}

input:focus {
  outline: none;
  border-color: initial;
}

.assessment-close-button {
  position: absolute;
  cursor: pointer;
  font-size: 20px;
  right: 20px;
  top: 4%;
}

.AssessmentSearch {
  display: flex;
  border-radius: 20px !important;
  background-color: #e0e5cf52 !important;
  padding: 0px 20px;
}

.rating-search-icon {
  position: absolute;
  right: 10px;
  top: 18px;
}

.mobile-rating-search-icon {
  position: absolute;
  right: 10px;
  top: 18px;
}

.landscape-rating-search-icon {
  position: absolute;
  right: 10px;
  top: 18px;
}

.tablet-rating-search-icon {
  position: absolute;
  right: 10px;
  top: 18px;
}

.new-viewer-page .tablet.leaflet-control-rotate.leaflet-bar.leaflet-control {
  top: 253px !important;
  width: 40px;
  height: 48px;
  left: 1px;
  bottom: calc(15% + 117px);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 2px;
}

.toggle-switch-Viewer {
  color: white;
  background-color: #255af6;
  border: 1px solid #255af6;
  border-radius: 20px;
  width: 125px;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 6px;
  font-weight: 600;
  border-color: #255af6 !important;
}

.toggle-switch-Viewer-MT {
  color: white;
  background-color: #255af6;
  border: 1px solid #255af6;
  border-radius: 20px;
  width: 125px;
  font-size: 12px;
  margin-top: 0px;
  font-weight: 600;
}

.toggle-MT-oldViewer {
  margin-left: 10px;
}

.toggle-MT-newViewer {
  margin-left: -12px;
}

.emoji-smile {
  margin-right: 5px;
  margin-bottom: 0px;
  padding-bottom: 3px;
}

.mobile-hamburger-icon.mobile-hamburger-realLandScapeicon-position {
  position: fixed;
  z-index: 999;
  left: 5px !important;
}

.mobile-hamburger-icon.mobile-hamburger-realInsideLandScapeicon-position {
  position: absolute;
  z-index: 999;
  left: 14px !important;
  background: none !important;
}

.desktopTrialSummary .modal-body,
.tabTrialSummary .modal-body,
.landScapeTrialSummary .modal-body,
.mobileTrialSummary .modal-body {
  padding: 0 !important;
}

.treatment-label-table {
  max-height: calc(100% - 45px);
  overflow: auto;
}

.treatment-label-model-table {
  max-height: 86vh;
  overflow: auto;
}

.treatment-label-container table td,
.treatment-label-table table td,
.treatment-label-model-table table td,
.treatment-label-container table th,
.treatment-label-table table th,
.treatment-label-model-table table th {
  border: 1px solid #dddddd;
}

table {
  width: 100% !important;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
}

.table-headings {
  font-size: 10px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
  padding: 8px;
}

.table-data {
  font-size: 10px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
  padding: 8px;
}

.treatmentLabelModel .modal-content {
  position: absolute;
  width: 97vw;
  height: 94vh;
  font-size: 0.75rem;
  z-index: 0;
  margin: 0px 0px 0px 20px;
  background-color: #f9f9f9;
}

.treatmentLabelModel .modal-dialog {
  max-width: fit-content;
  position: absolute;
}

::-webkit-scrollbar {
  height: 5px;
}

.chart-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.trial-dropdown-label {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 16px;
  color: #0d1a40;
  text-align: left !important;
  line-height: 8px;
  display: flex;
  justify-content: flex-start;
}

.mobile-trial-dropdown-label {
  margin-bottom: 0;
  font-size: 16px;
  color: #0d1a40;
  text-align: left;
  line-height: 8px;
  display: inline-block;
  width: 110px;
}

.RatingModalSearch::placeholder {
  color: #0d1a40 !important;
}

.MobileRatingModalSearch::placeholder {
  color: #0d1a40 !important;
}

.assessment-scroll::-webkit-scrollbar-thumb,
.assessment-mobile-container::-webkit-scrollbar-thumb,
.scrollbar-assessment::-webkit-scrollbar-thumb,
.desktopRatingModalBody::-webkit-scrollbar-thumb,
.mobileRatingModalBody::-webkit-scrollbar-thumb,
.tabletRatingModalBody::-webkit-scrollbar-thumb,
.flight-date-container::-webkit-scrollbar-thumb,
.traildropdown-mobile-container::-webkit-scrollbar-thumb,
.mobileLandscapeScroll::-webkit-scrollbar-thumb,
.trialDropdownTablet::-webkit-scrollbar-thumb,
.trial-dropdown .dropdown-menu::-webkit-scrollbar-thumb,
.split-scrollbar::-webkit-scrollbar-thumb,
.tab .modal-content::-webkit-scrollbar-thumb,
.mobile .modal-content::-webkit-scrollbar-thumb,
.treatment-label-table::-webkit-scrollbar-thumb,
.treatment-label-model-table::-webkit-scrollbar-thumb,
.desktop-protocol-trials::-webkit-scrollbar-thumb,
.protocolTrial-dropdown::-webkit-scrollbar-thumb,
.infoIconContainer::-webkit-scrollbar-thumb {
  background-color: #aaa9a9;
}

.portrait-flight-date-title {
  color: #0d1a40;
  font-size: 12px;
  font-style: normal;
  padding-top: 12px;
  margin-left: 85px;
  margin-bottom: 10px;
}

table.tooltip-table-manual tr td {
  text-align: left;
}

span.color-square {
  width: 10px;
  display: inline-block;
  height: 10px;
  margin-left: 10px;
  border-radius: 2px;
  min-width: 10px;
}

.viewer-bar-buttons {
  font-size: 12px !important;
  white-space: nowrap !important;
  width: 124px !important;
  border-radius: 20px !important;
  font-family: Helvetica, sans-serif;
  border: none !important;
  box-shadow: none !important;
}

.flight-overlay-button {
  margin-left: -5px !important;
  margin-top: 8px !important;
}

.flight-overlay-button-withoutdate {
  margin-left: -5px !important;
  margin-top: 13px !important;
}

.flight-overlay-toggle {
  margin-left: 67px !important;
  top: 0px !important;
}

.overlay-flight-dropdown-toggle {
  border: 0.5px solid #e8e8e8 !important;
  width: 68px !important;
  height: 51px !important;
  padding: 0px 0px 0px 8px !important;

  background-color: white;
  box-shadow: none;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.flight-menu-button {
  margin-top: 20px;
}

.baseOF-dropdown.dropend .dropdown-menu {
  width: 270px !important;
  overflow-y: auto;
  transform: translate3d(320px, 0px, 0px) !important;
  min-height: 288px !important;
  max-height: 394px !important;
}

.baseOF-dropdown-menu-show {
  width: 210px !important;
  height: 351px !important;
}

.baseOF-dropdown.dropend button {
  width: 100%;
  align-self: flex-start;
  text-align: left;
  height: 72px;
  padding: 0 12px;
  border-bottom: 1px solid rgb(237, 237, 237);
  border-radius: 0;
}

.baseOF-dropdown.baseOF-dropdown-type.dropend button {
  height: 32px;
  padding: 0 16px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.baseOF-main-dropdown .baseOF-dropdown-menu {
  transform: translate3d(0px, 60px, 0px) !important;
}

.baseOF.dropend .dropdown-menu {
  width: 280px;
  overflow-y: auto;
  transform: translate3d(211px, 0px, 0px) !important;
  min-height: 300px;
  max-height: 350px;
}

span.baseOF-option {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.baseOF-row-hover:hover,
.baseOF-row-hover:focus {
  background-color: #ffe8bc;
}

.baseOF-dropdown.show.dropend .dropdown-toggle {
  background-color: #ffe8bc !important;
}

.overlay-menu {
  border-bottom: 0.5px solid #e8e8e8 !important;
  height: 72px !important;
  padding-top: 15px;
  padding-left: 20px;
}

.overlay-menu-boundingbox {
  border-bottom: 0.5px solid #e8e8e8 !important;
  height: 90px !important;
  padding-top: 15px;
  padding-left: 20px;
}

.overlay-manual-menu {
  border-bottom: 0.5px solid #e8e8e8 !important;
  height: 51px !important;
  padding-top: 8px;
  padding-left: 20px;
}

.remove-border {
  border-bottom: none !important;
}

.overlay-label {
  font-size: 10px;
  font-weight: 600;
  color: #0d1a40;
  margin-bottom: 0px;
}

.overlay-radio {
  margin-left: 27px;
}

.dd-flight-title {
  color: #0d1a40;
  font-size: 12px;
  font-style: normal;
  padding-top: 20px;
  padding-bottom: 15px;
  margin-left: 20px;
}

.base-overlay-input-label {
  font-size: 12px;
  font-weight: 400;
  color: #0d1a40;
  display: flex;
  align-items: center;
}

.overlay-flight-icon {
  border: none !important;
  background-color: transparent;
}

.overlay-item {
  gap: 26px;
}

.overlay-item-withboundingbox {
  flex-wrap: wrap !important;
  gap: 6px 26px !important;
}

.newviewer-buttons-tooltip .tooltip-inner {
  width: 367px !important;
  height: 52px !important;
  max-width: 367px !important;
  background-color: #255af6;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-family: Helvetica, sans-serif;
}

.panel-title-bar {
  height: 30px;
  width: 100%;
  background-color: #f9f9f9;
}

.flight-date-section {
  display: block;
}

@media (min-width: 992px) {
  .flight-date-section {
    display: none;
  }
}

.topbar-tooltip {
  margin-top: -11px;
  color: white;
  white-space: nowrap;
}

.topbar-tooltip .tooltip-inner {
  background-color: #255af6 !important;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  max-width: 805px !important;
}

.save-tooltip .tooltip-inner {
  width: 1005px !important;
  height: 52px !important;
  max-width: 105px !important;
  position: absolute;
  right: 0px;
  bottom: 21px;
  background-color: #255af6;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Helvetica, sans-serif;
}

.toogle-desktop {
  margin-left: "-20px";
}

.parent-Dropdown {
  box-shadow: "0 0 10px rgba(0, 0, 0, 0.3) !important";
}

.newviewer-tablet-buttons-tooltip .tooltip-inner {
  min-width: 367px !important;
  height: 52px !important;
  max-width: fit-content;
  background-color: #255af6;
  border-radius: 5px;
  align-items: center;
  display: flex;
  font-family: Helvetica, sans-serif;
}

/*---------------Hamburger device--------------*/
.latest-hamburger-icon {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  position: fixed;
  z-index: 999;
  right: 0px;
  width: 68px;
  height: 51px;
}

.selector-menu {
  border-bottom: 1px solid #ededed;
  padding: 15px;
}

.selectors-text {
  font-size: 16px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
}

.hamburger-accordion-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
}

.trial-text {
  font-size: 12px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
}

.mobileportrait-traildropdown-listitem {
  padding-left: 15px;
}

.mobile-assessments-button {
  padding: 15px 10px 15px 15px;
  color: #0d1a40 !important;
  font-size: 12px;
  font-weight: 700;
  background-color: white !important;
  width: 100%;
  border: none;
  display: flex;
  box-shadow: none;
  justify-content: space-between;
}

.assessmemt-icon-mobile {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  position: fixed;
  z-index: 999;
  left: 0px;
  width: 68px;
  height: 51px;
  margin-top: 54px !important;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.mobile-landscape-date-combination-close {
  position: absolute;
  right: 25px;
}

.mobile-charts-menu {
  position: absolute;
  z-index: 99999;
  background-color: white;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0px;
}

.newviewer-mobile-buttons-tooltip .tooltip-inner {
  width: 100vw !important;
  height: 52px !important;
  max-width: 432px !important;
  background-color: #255af6;
  border-radius: none !important;
  align-items: center;
  display: flex;
  font-family: Helvetica, sans-serif;
  position: absolute !important;
  transform: none !important;
}

.newviewer-mobile-buttons-tooltip {
  top: 54px !important;
  transform: none !important;
}

.newviewer-mobile-landscape-buttons-tooltip .tooltip-inner {
  width: 100vw !important;
  height: 52px !important;
  max-width: 432px !important;
  background-color: #255af6;
  border-radius: none !important;
  align-items: center;
  display: flex;
  font-family: Helvetica, sans-serif;
  transform: none !important;
  position: fixed !important;
  right: 0 !important;
}

.newviewer-mobile-landscape-buttons-tooltip {
  top: 54px !important;
  transform: none !important;
}

.newviewer-assessment-mobile-buttons-tooltip .tooltip-inner {
  width: 100vw !important;
  height: 52px !important;
  max-width: 430px !important;
  background-color: #255af6;
  border-radius: none !important;
  align-items: center;
  display: flex;
  font-family: Helvetica, sans-serif;
}

/*-----------------Rating modal draggable and resizable css ---------*/
.desktopRatingModal .modal-content {
  /* this is because we can resize this background so we'll hide it. The coloring will
  now be maintained by the modal-resizable class */
  background: transparent;
}

.desktopRatingModal .modal-content > .modal-resizable {
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ebedee;
  border: 0;
  border-radius: 0.2em;
  box-shadow: 0 0 0 1px rgba(101, 108, 126, 0.5) inset, 0 1px 3px 1px rgba(64, 64, 64, 0.5);
  height: auto;
}

.desktopRatingModal .modal-resizable > .modal-body {
  /* This is to account for the height of the footer */
  /* padding: 1em 1em 5em 1em;
  overflow-y: auto;
  flex:1; */
}

.desktopRatingModal .modal-footer {
  /* this is to keep the footer pinned to the bottom when we resize */
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10 !important;
}

.content-TrialSummary {
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
  font-size: 12px;
}

.date-TrialSummary {
  font-family: Helvetica, sans-serif;
  color: #0d1a40;
  font-weight: 700;
  font-size: 12px;
}

.rating-accordion-open.accordion-item .accordion-collapse.collapse {
  display: block !important;
}

.desktopRatingModal .modal-header {
  align-items: center !important;
}

.mobile-ls-cont .plot-container {
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
  margin-right: 10px;
  overflow-x: scroll;
  position: relative;
  bottom: 3px;
}

/* chat List css */

.com-heading {
  color: #0d1a40;
  font-size: 16px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  padding-left: 16px;
}

.chat-text {
  padding-right: 20px;
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.chat-heading-text {
  color: #0d1a40;
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  position: relative;
  top: 14px;
  padding-left: 20px;
}

.search-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  padding: 10px 0px 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.chatsearch-input {
  width: 100%;
  border: none;
  height: 40px;
  background-color: #f1f2f3;
  border-radius: 20px;
  font-size: 12px;
  color: #0d1a40;
  font-weight: 400;
  padding: 0 40px 0 15px;
}

.chatsearch-input::placeholder {
  color: #0d1a40;
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.nested-dropdown-design {
  color: #0d1a40;
  font-family: Helvetica, sans-serif;
  position: relative;
  top: 31px;
}

.accordion-text-heading {
  color: #0d1a40;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  padding-top: 15px;
  padding-left: 5px;
}

.accordion-content-text {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  padding-top: 2px;
}

.accordion-text-teams {
  color: #255af6;
  font-size: 12px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  padding-top: 2px;
  margin-left: 0px;
}

.icon-no-border {
  border: none;
  background: none;
  padding: 0;
  margin-left: 3px;
}

.count-accordion {
  border: none;
  background: none;
  padding: 0;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
}

.bold-text {
  font-weight: 700;
}

.not-bold-text {
  font-weight: 400;
}

.search-loading-chat-text {
  font-size: 12px;
  font-weight: 700;
  color: #0d1a40;
}

.chat-text-result {
  color: #0d1a40;
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  position: relative;
  top: 29px;
  padding-left: 260px;
  cursor: pointer;
}

.search-results {
  background-color: white;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1px;
  margin-left: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  width: 340px;
  height: 125px;
  gap: 0px;
  position: absolute;
  z-index: 99;
}

.search-result-item {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 500;
  font-family: Helvetica, sans-serif;
  position: relative;
  padding-left: 20px;
}

.add-people-modal-header.modal-header {
  border-bottom: none !important;
  padding: 16px 16px 0 16px !important;
  color: #0d1a40;
  font-weight: 700;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
}

.add-people-modal .modal-content {
  width: 374px !important;
  height: 515px !important;
}

.add-people-modal-tab .modal-content {
  width: 374px !important;
  height: 515px !important;
}

.add-people-landscape .modal-content {
  width: 374px !important;
  height: 376px !important;
}

.addpeople-chatsearch-input::placeholder {
  padding-left: 0px;
  color: #0d1a40;
}

.addpeople-chatsearch-input {
  border: none;
  height: 40px;
  background-color: #f1f2f3;
  border-radius: 20px;
  font-size: 12px;
  color: #0d1a40;
  font-weight: 400;
  padding-left: 15px;
}

.modal-accordion-text-teams {
  color: #255af6;
  font-size: 12px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  padding-top: 2px;
  margin-left: 100px;
}

.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-popup {
  width: 427px;
  height: 221px;
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 415px;
  left: 510px;
  gap: 0px;
  opacity: 1;
  z-index: 100000000;
}

.delete-popup-content {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: 21px;
}

.delete-popup-divider {
  width: 427px;
  border-top: 0.5px solid #d9d9d9;
  position: relative;
  top: 39px;
}

.cancel-button {
  border: none;
  background-color: white;
  color: #0d1a40;
  font-weight: 600;
  font-size: 16px;
}

.confirm-button {
  border: none;
  background-color: #0d1a40;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  width: 96px;
  height: 40px;
  margin-left: 15px;
}

.add-people-modal .modal-body {
  padding: 0px !important;
}

.modal-center-spinner-full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh !important;
}

.modal-search-results {
  background-color: white;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  width: 94%;
  margin: 5px 0px 0px 14px !important;
  height: 125px;
  position: absolute;
  z-index: 99;
}

.modal-search-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  padding: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-item {
  position: relative;
}

.child-item {
  margin-left: -10px;
}

.children-container {
  padding-left: 20px;
}

.parent-item {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.accordion-chat-content-text {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
}

.chat-tooltip {
  opacity: 1 !important;
  margin-bottom: -6px !important;
  position: fixed !important;
  z-index: 1050 !important;
}

.chat-tooltip .tooltip-inner {
  width: 100vw !important;
  height: 52px !important;
  max-width: 360px !important;
  background-color: #255af6 !important;
  border-radius: none !important;
  align-items: center;
  display: flex;
  font-family: Helvetica, sans-serif;
  right: 7px !important;
  opacity: 2 !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 13.8px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.chat-tooltip .tooltip-arrow::before {
  border-top-color: #255af6;
}

.new-chat-text {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 126px;
  height: 36px;
  border-radius: 20px;
  background-color: #0d1a40;
  color: white;
}

.feedback-opslink {
  color: #255af6 !important;
  text-decoration: underline;
}

.chatlist-center-spinner-full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 220px;
}

.landscape-chatlist-center-spinner-full-height {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 120px;
}

.share-link-button {
  position: fixed;
  cursor: pointer;
}

.select-plots-dropdown {
  position: absolute;
  z-index: 99999;
  top: 32px;
  right: 0px;
  background-color: white;
  width: 212px;
  max-height: 50%;
  overflow-y: scroll;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  padding: 4px 12px;
}

.custom-checkbox.custom-checkbox-viewer.form-check {
  padding-left: 0px;
  font-size: 14px;
  color: #0d1a40 !important;
}

.custom-checkbox.custom-checkbox-viewer.form-check label {
  padding-left: 4px;
}

.custom-checkbox.custom-checkbox-viewer.form-check input:disabled {
  border-color: #6c757d;
}

.drone-images-message {
  width: 340px !important;
  height: 52px !important;
  background-color: #255af6;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-family: Helvetica, sans-serif;
  color: white;
  margin: 30px auto;
  position: relative;
  z-index: 999;
}

.plot-images-message {
  width: 508px !important;
  height: 52px !important;
  background-color: #255af6;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-family: Helvetica, sans-serif;
  color: white;
  margin: 20px auto;
}

.plot-image-wrapper {
  height: calc(100% - 30px) !important;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.plot-image-wrapper::-webkit-scrollbar {
  width: 10px;
}

.plot-image-wrapper::-webkit-scrollbar-thumb {
  cursor: pointer;
}

.plot-image-wrapper.expanded {
  display: grid !important;
  gap: 0px;
  /* Adjust gap between images as needed */
}

/* .plot-image-wrapper.one-image {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.plot-image-wrapper.two-images {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.plot-image-wrapper.three-images {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.plot-image-wrapper.three-images .image-wrapper.first-image {
  grid-row: span 2;
}

.plot-image-wrapper.four-images,
.plot-image-wrapper.more-than-four-images {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.plot-image-wrapper.more-than-four-images .image-wrapper {
  height: 50%;
} */

.plot-image-wrapper.expanded.one-image .image-wrapper {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.plot-image-wrapper.two-images {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

/* .plot-image-wrapper.two-images .image-wrapper {
  height: 100%;
  width: 100%;
} */

/* .plot-image-wrapper.three-col-images {
  grid-template-columns: 1fr 1fr 1fr;
  grid-row: auto;
}

.plot-image-wrapper.three-images {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
} */

.plot-image-wrapper.three-images .image-wrapper {
  grid-row: auto;
}

.plot-image-wrapper.three-images .image-wrapper.first-image {
  grid-row: span 2;
}

.plot-image-wrapper.three-col-images,
.plot-image-wrapper.four-images,
.plot-image-wrapper.more-than-four-images {
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 100%;
}

/* .plot-image-wrapper.more-than-four-images .image-wrapper {
  width: 100%;
  height: 100%;
} */

.mobile-chat-heading-text {
  color: #0d1a40;
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  position: relative;
  top: 17px;
  padding-left: 33px;
}

.mobile-search-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0px 21px 0px 33px !important;
}

.mobile-chat-text {
  padding-right: 35px;
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.plot-image-expand-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  background: white;
  right: 120px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9995;
  pointer-events: auto;
}

.plot-image-wrapper .plot-image-expand-icon {
  visibility: hidden;
}

.plot-image-wrapper .leaflet-container .plot-image-expand-icon {
  visibility: visible;
}

.plot-image-wrapper .plot-image-cancel-icon {
  visibility: hidden;
}

.plot-image-wrapper .leaflet-container .plot-image-cancel-icon {
  visibility: visible;
}

.plot-image-cancel-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  right: 20px;
  top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9995;
  pointer-events: auto;
}

.plot-image-summary {
  position: absolute;
  z-index: 999;
  top: 25px;
  left: 28px;
  font-size: 12px;
  color: black;
  display: none;
  background: #f9f9f9;
  padding: 0 2px;
  border-radius: 3px;
}

/*------------compare plots css---------*/
.select-first-compare-plot-dropdown {
  width: 174px;
  height: 237px;
  position: absolute;
  z-index: 99999;
  top: 30px;
  right: 0px;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  padding: 4px 12px;
}

.select-second-compare-plot-dropdown {
  width: 174px;
  height: 237px;
  position: absolute;
  z-index: 99999;
  top: 30px;
  right: 28px;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  padding: 4px 12px;
}

.compare-treatments-menu {
  width: 53px;
  background-color: white;
  height: 148px;
  max-height: 105px;
  overflow-y: scroll;
  margin-left: 95px;
  border: 1px solid #ededed;
}

.compare-reps-menu {
  width: 53px;
  background-color: white;
  max-height: 105px;
  overflow-y: scroll;

  margin-left: 95px;
  border: 1px solid #ededed;
}

.leaflet-container .plot-image-summary {
  display: block;
}

.compare-chevron-down {
  border: 1px solid #ededed;
  border-radius: 20px;
  padding-left: 12px;
  width: 72px;
  height: 28px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
}

.add-people-modal-cancel {
  width: 68px;
  height: 18px;
  background-color: #ffffff;
  color: #0d1a40;
  font-size: 16px;
  font-weight: 700;
  font-family: Helvetica;
  margin-right: 18px;
}

.add-people-modal-add {
  width: 79px !important;
  height: 40px !important;
  background-color: #0d1a40;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  font-family: Helvetica;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plot-dots-menu-text {
  margin: 11px 10px 10px 10px;
  height: 18px;
}

.plot-image-three-dots {
  position: absolute;
  width: 32px;
  height: 32px;
  background: white;
  right: 75px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9995;
  pointer-events: auto;
}

.report-search::placeholder {
  color: #0d1a40 !important;
  font-size: 12px;
}

.report-search {
  width: 300px;
  display: flex;
  margin: 8px auto 35px auto;
  border-radius: 20px !important;
  background-color: #f1f2f3 !important;
  outline: 1px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.report-search {
  width: 300px !important;
}

.description-textarea .form-control::placeholder {
  color: #0d1a40 !important;
}

.report-modal .modal-content {
  min-width: 523px;
}

.description-textarea .form-control:focus {
  border: 1px solid #ced4da;
}

.description-textarea .form-control {
  min-width: 468px;
}

.trial-description-textarea .form-control::placeholder {
  color: #0d1a40 !important;
}
.trial-description-textarea .form-control:focus {
  border: 1px solid #ced4da;
}

.trial-description-textarea .form-control {
  min-width: 468px;
  resize: none !important;
  font-size: 12px !important;
  color: #0d1a40 !important;
  border-radius: 10px !important;
}

.report-title {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}
.mobile-report-title {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 700;
  color: #303135;
}

.report-x-button {
  position: absolute;
  right: 5px;
  top: 35px;
  transform: translateY(-50%);
}

.report-modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}
.trial-report-modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  padding: 1rem;
}
.report-modal-text {
  font-family: Helvetica;
  font-size: 12px;
}

.report-modal-buttons {
  width: auto;
  height: 30px;
  top: 268px;
  left: 487px;
  border-radius: 20px;
  border: none !important;
  box-shadow: none !important;
  font-size: 12px;
  font-family: Helvetica;
  padding: 0 16px;
}

.report-modal-cancel {
  border: none;
  background-color: white;
  color: #293306;
  font-weight: 600;
  font-size: 16px;
}
.trial-report-modal-cancel {
  border: none;
  background-color: white;
  color: #293306;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}

.report-modal-submit {
  border: none;
  background-color: #0d1a40;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  height: 40px;
  width: 111px;
}

.success-modal-ok {
  border: none;
  background-color: #0d1a40;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  width: 96px;
  height: 40px;
}

.report-assessment-names {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #0d1a40;
}

.report-drone-manual-img {
  font-weight: 700;
  font-size: 12px;
  color: #0d1a40;
}

.report-modal-no-results-text {
  font-size: 12px;
  color: #ff3f3f;
  font-weight: 700;
}

.trial-report-modal .modal-dialog {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.trial-report-modal .modal-content {
  min-width: 717px;
}

.trial-report-drone-manual-img {
  font-weight: 700;
  font-size: 12px;
  color: #0d1a40;
  padding-left: 8px;
}

.custom-trail-radio-button input[type="radio"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  background-color: #fff;
  border: 1px solid #0d1a40 !important;
  outline: none !important;
  border-radius: 50% !important;
  width: 12px;
  height: 12px;
  position: relative;
}

.custom-trail-radio-button input[type="radio"]:checked {
  border: 1px solid #e08330 !important;
}

.custom-trail-radio-button input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 1px;
  width: 8px;
  height: 8px;
  background-color: #e08330 !important;
  border-radius: 50%;
}
.file-name-pill {
  background-color: #ededed;
  border-radius: 2px;
  padding: 2px 4px;
}
.description-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  color: #979797;
}
.attach-file-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 700;
  color: #255af6;
}
.optional-text {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  color: #979797;
}
.file-name {
  font-size: 12px;
  font-weight: 700;
  color: #0d1a40;
}
.remove-all-text {
  white-space: nowrap;
  font-size: 12px;
  color: #0d1a40;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}

.plot-download-tooltip-text {
  font-size: 10px;
  font-family: Helvetica, Arial, sans-serif;
  color: #636363;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plot-download-icon-container {
  cursor: pointer;
  padding-right: 24px;
  padding-bottom: 2px;
  position: relative;
}

.plot-download-custom-tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-68%);
  background-color: #ededed;
  padding: 5px;
  white-space: nowrap;
  z-index: 9999;
  border-radius: 2px;
}

.copytoclipboard-spinner {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999;
  background: transparent;
}
span.modal-join-team {
  padding: 5px;
}

span.modal-join-team:not(.disabled):hover {
  background-color: #ededed;
  border-radius: 50%;
}

span.join-teams-text {
  padding: 2px 4px;
  border-radius: 2px;
}

span.join-teams-text:not(.disabled):hover {
  background: #ededed;
}

.mobile-three-dots {
  padding-left: 15px;
  padding-right: 15px;
}

.mobile-three-dots-menu {
  width: 147px;
  height: 104px;
  background-color: #ffffff;
  position: absolute;
  top: 44px;
  right: 15px;
}

.mobile-chat-div {
  margin-top: 12px;
  margin-bottom: 6px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ededed;
}

.mobile-report-an-issue {
  margin-left: 10px;
  margin-top: 15px;
}

.mobile-trial-report-modal .modal-dialog .modal-content {
  height: 100vh !important;
  width: 100vw !important;
}

.mobile-trial-report-modal .modal-dialog {
  margin: 0 !important;
  max-width: 100vw !important;
}

.fade.mobile-trial-report-modal.modal.show {
  overflow: hidden !important;
}

.mobile-modal-header {
  border-bottom: 4px solid #dee2e6 !important;
}

.mobile-description-textarea .form-control::placeholder {
  color: #0d1a40 !important;
}

.mobile-description-textarea .form-control:focus {
  border: 1px solid #ced4da;
}

.mobile-description-textarea .form-control {
  resize: none !important;
  font-size: 12px !important;
  color: #0d1a40 !important;
  border-radius: 10px !important;
}

.all-buttons {
  overflow-x: scroll;
  white-space: nowrap;
}

.all-buttons::-webkit-scrollbar-thumb {
  background: #fff;
}

.success-modal-body {
  width: 560px;
}

.assessments-seperation {
  margin: 0px 10px;
  border-bottom: 1px solid #ededed;
}

.assessments-div {
  max-height: 150px;
  overflow: scroll;
}

.file-display {
  display: none;
}

.assessments-input-group {
  width: 459px;
}

.assessments-input-group .input-group {
  width: 459px;
  position: relative;
  margin-left: 5px;
}

.mobile-assessments-input-group .input-group {
  position: relative;
  margin-left: 5px;
}

.trial-report-modal-footer {
  border-top: 1px solid #dee2e6 !important;
  margin-top: 8px;
  padding: 8px 0px;
}

.mobile-trial-report-modal-footer {
  border-top: 1px solid #dee2e6 !important;
  margin-top: 25px;
  padding: 25px 0px 10px 0px;
}

.mobile-success-modal-body {
  width: auto;
}

.landscape-report-modal-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 1rem;
}

.web-chat-icon {
  margin-right: 25px;
  margin-top: 4px;
}
.tablet-chat-icon {
  margin-right: 12px;
  margin-top: 4px;
}
.tablet-assessment-scroll {
  height: 350px;
  max-height: 252px;
  overflow-y: auto;
}
.device-dropdown-menu {
  position: relative;
  display: flex;
  align-items: center;
}
.mobile-image-dates-container {
  position: absolute;
  left: 68px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 430px;
  width: 297px;
  overflow: auto;
  top: 31px;
  z-index: 9999;
}
.expand-button {
  border: none;
  height: 30px;
  background-color: #f9f9f9 !important;
  box-shadow: none;
}
.clear-all-span {
  border-bottom: 1px solid;
  padding-bottom: 0px !important;
  margin-right: 15px;
  color: #0d1a40;
  font-size: 14px;
  cursor: pointer;
  z-index: 99;
}
.select-plot-info {
  font-size: 12px;
  color: #0d1a40;
  font-weight: 400;
  display: flex;
  padding-top: 4px;
}
.select-plot-text {
  text-wrap: wrap;
  padding-left: 8px;
  line-height: 14px;
}
.link-copied-text {
  position: relative;
  top: 21px;
  right: 18px;
  font-size: 12px;
  white-space: nowrap;
}
.plot-images-message.mobile-image-message {
  width: 410px !important;
}

.first-compare-base-dropdown.dropend .dropdown-menu {
  width: 255px !important;
  overflow-y: auto;
  transform: translate3d(316px, 0px, 0px) !important;
  min-height: 288px !important;
  max-height: 394px !important;
}

.first-compare-base-dropdown.dropend button {
  width: 100%;
  align-self: flex-start;
  text-align: left;
  height: 50px;
  padding: 0 12px;
  border-bottom: 1px solid rgb(237, 237, 237);
  border-radius: 0;
}

.second-compare-base-dropdown.dropend .dropdown-menu {
  max-width: 255px !important;
  overflow-y: auto;
  transform: translate3d(316px, 0px, 0px) !important;
  min-height: 288px !important;
  max-height: 394px !important;
  inset: 0px !important;
}

.second-compare-base-dropdown.dropend button {
  width: 100%;
  align-self: flex-start;
  text-align: left;
  height: 50px;
  padding: 0 12px;
  border-bottom: 1px solid rgb(237, 237, 237);
  border-radius: 0;
}

.flight-overlay-toggle-compare {
  margin-left: 67px !important;
  top: 3px !important;
  min-height: 420px;
  width: 315px;
}

.flight-overlay-toggle-compare-two {
  margin-left: 67px !important;
  top: 32px !important;
  min-height: 420px;
  width: 315px;
}

.comapre-image-source {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 22px;
}

.compare-calender-icon {
  padding-left: 7px;
  color: #0d1a40;
  width: 30px;
}

.compare-flight-text {
  color: #0d1a40;
  font-size: 12px;
}

.comapare-flight-options {
  font-size: 12px;
  color: #0d1a40;
  cursor: "pointer";
}

.comapare-treatment-reps {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.compare-plot-dates-container {
  text-align: center;
  display: inline-block;
  position: relative;
}
.compare-plot-dates-text1 {
  display: block;
}
.compare-plot-dates-text2 {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.compare-plot-flight-button {
  position: absolute;
  z-index: 9999;
}
.image-navigation-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 1;
  color: #0d1a40;
  font-size: 12px;
  background: white;
  width: 95px;
  border-radius: 20px;
  justify-content: space-around;
  height: 30px;
  line-height: 14px;
}
